export const environment = {
  production: true,
  config: {
    appUrl: 'https://app.uat.e-stmt.com',
    baseApiUrl: 'https://api.uat.e-stmt.com',
    websiteUrl: 'https://www.uat.e-stmt.com',
    shortDateFormat: 'dd/MM/yyyy',
    dateFormat: 'dd/MM/yyyy, h:mm:ss a',
    maxIndexRow: 20000,
    maxPdfFileSizeBytes: 20000000,
    maxZipFileSizeBytes: 500000000,
    maxFileSizeBytes: 10000000,
    experimentalFeature: true,
    contextSensitiveHelp: false,
    supportEmail: 'support@mybillone.com',
    smtpFromEmail: 'uat@mybillone.com',
    maxImageSizeBytes: 20000000,
    maxImageTotal: 100,
    canTopUpSms: true,
    isPaidPlanEnabled: true,
    deploymentMode: 'on-premise',
    firebase: {
      apiKey: 'AIzaSyBJwLNvl_jqvjeGPpUG0wY4AOzsDOPS8yQ',
      authDomain: 'grabbill-dev.firebaseapp.com',
      projectId: 'grabbill-dev',
      storageBucket: 'grabbill-dev.appspot.com',
      messagingSenderId: '645960359111',
      appId: '1:645960359111:web:dd6b86de5f7e92a69872e2',
      measurementId: 'G-8P3TXRSHFX',
    },
  },
};
