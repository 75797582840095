import { NgModule } from '@angular/core';
import { NZ_ICONS, NzIconModule } from 'ng-zorro-antd/icon';

import {
  AuditOutline,
  BankOutline,
  CheckCircleOutline,
  CheckCircleTwoTone,
  CloseCircleFill,
  CloseOutline,
  CloudUploadOutline,
  ContactsOutline,
  ContainerOutline,
  CopyOutline,
  CreditCardOutline,
  DashboardOutline,
  DatabaseOutline,
  DeleteOutline,
  DollarOutline,
  DownloadOutline,
  EditOutline,
  ExceptionOutline,
  ExperimentTwoTone,
  EyeInvisibleOutline,
  EyeOutline,
  FileExclamationOutline,
  FileOutline,
  FilePdfOutline,
  FileTextOutline,
  FilterOutline,
  FolderOutline,
  FormOutline,
  GlobalOutline,
  GoogleOutline,
  HistoryOutline,
  HomeOutline,
  InboxOutline,
  InfoCircleOutline,
  LockOutline,
  LogoutOutline,
  MailOutline,
  MenuFoldOutline,
  MenuUnfoldOutline,
  MessageOutline,
  MobileOutline,
  NumberOutline,
  PhoneOutline,
  PictureOutline,
  PlusOutline,
  PoweroffOutline,
  ProfileOutline,
  RedoOutline,
  QuestionCircleOutline,
  QuestionOutline,
  SafetyOutline,
  ScheduleOutline,
  SearchOutline,
  SelectOutline,
  SettingOutline,
  SolutionOutline,
  TableOutline,
  TeamOutline,
  UserOutline,
  WhatsAppOutline
} from '@ant-design/icons-angular/icons';

const icons = [
  AuditOutline,
  BankOutline,
  CheckCircleOutline,
  CheckCircleTwoTone,
  CloseCircleFill,
  CloseOutline,
  CloudUploadOutline,
  ContactsOutline,
  ContainerOutline,
  CopyOutline,
  CreditCardOutline,
  DashboardOutline,
  DatabaseOutline,
  DeleteOutline,
  DollarOutline,
  DownloadOutline,
  EditOutline,
  ExceptionOutline,
  ExperimentTwoTone,
  EyeInvisibleOutline,
  EyeOutline,
  FileExclamationOutline,
  FilePdfOutline,
  FileTextOutline,
  FileOutline,
  FilterOutline,
  FolderOutline,
  GlobalOutline,
  GoogleOutline,
  HistoryOutline,
  InboxOutline,
  InfoCircleOutline,
  LockOutline,
  LogoutOutline,
  HomeOutline,
  MailOutline,
  MessageOutline,
  MenuFoldOutline,
  MenuUnfoldOutline,
  MobileOutline,
  NumberOutline,
  PictureOutline,
  PhoneOutline,
  PlusOutline,
  PoweroffOutline,
  ProfileOutline,
  RedoOutline,
  QuestionCircleOutline,
  QuestionOutline,
  ScheduleOutline,
  SearchOutline,
  SelectOutline,
  SettingOutline,
  SolutionOutline,
  SafetyOutline,
  TableOutline,
  TeamOutline,
  UserOutline,
  FormOutline,
  WhatsAppOutline
];

@NgModule({
  imports: [NzIconModule],
  exports: [NzIconModule],
  providers: [{ provide: NZ_ICONS, useValue: icons }],
})
export class IconsProviderModule {}
