<div box>
  <div title>Reset Password</div>
  <div *ngIf="isResetSuccessful">
    <div class="pt-4 pb-8 text-left">
      Password has been reset.
    </div>

    <button nzSize="large" [routerLink]="['/', 'login']" type="button" nz-button nzBlock nzType="primary">
      Back to login
    </button>
  </div>
  <div *ngIf="!isResetSuccessful">
    <div class="pt-4 pb-8 text-left">
      Please enter your new password.
    </div>
    <form nz-form [formGroup]="form" (ngSubmit)="submitForm()">
      <nz-form-item>
        <nz-form-control [nzErrorTip]="getErrorMessage(form.controls['newPassword'])">
          <nz-input-group nzPrefixIcon="lock" nzSize="large">
            <input type="password" nz-input formControlName="newPassword" placeholder="New Password" />
          </nz-input-group>
        </nz-form-control>
      </nz-form-item>
      <button nzSize="large" [nzLoading]="isLoading" nz-button nzBlock [nzType]="'primary'">
        Reset password
      </button>
    </form>
  </div>
</div>
