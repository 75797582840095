export enum InvoiceStatus {
  NEW = 'NEW',
  PROCESSING = 'PROCESSING',
  PAYMENT_FAILED = 'PAYMENT_FAILED',
  PAID = 'PAID',
  CARRY_FORWARD = 'CARRY_FORWARD',
  VOID = 'VOID',
  FREE = 'FREE',

  PAYMENT_WAIVED = 'PAYMENT_WAIVED',
}
