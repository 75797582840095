<div box>
  <div title>Forget Password</div>
  <div *ngIf="isForgetPasswordSuccessful">
    <div class="pt-4 pb-8 text-left">
      We've sent email with reset password instruction.
      <br /><br />
      If you don't see an email from us within a few minutes, check your spam folder. Please contact us if you are not able to find the email.
    </div>

    <button nzSize="large" [routerLink]="['/', 'login']" type="button" nz-button nzBlock nzType="primary">
      Back to login
    </button>
  </div>
  <div *ngIf="!isForgetPasswordSuccessful">
    <div class="pt-4 pb-8 text-left">
      Please enter the email address which you like to reset your password.
    </div>
    <form nz-form [formGroup]="form" (ngSubmit)="submitForm()">
      <nz-form-item>
        <nz-form-control nzErrorTip="Required">
          <nz-input-group nzPrefixIcon="email" nzSize="large">
            <input type="text" nz-input formControlName="email" placeholder="Email"/>
          </nz-input-group>
        </nz-form-control>
      </nz-form-item>
      <button [nzLoading]="isLoading" nzSize="large" nz-button nzBlock nzType="primary">
        Forget password
      </button>
      <div class="text-center pt-4">
        <a [routerLink]="['/', 'login']">Back to login page</a>
      </div>
    </form>
  </div>

</div>
