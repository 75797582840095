export enum Privilege {
  MANAGE_PROFILE = 'MANAGE_PROFILE',
  MANAGE_ACCOUNT = 'MANAGE_ACCOUNT',
  MANAGE_PLAN = 'MANAGE_PLAN',
  MANAGE_USER = 'MANAGE_USER',
  MANAGE_SMTP_IMAP = 'MANAGE_SMTP_IMAP',
  MANAGE_IMAGES = 'MANAGE_IMAGES',
  MANAGE_BOUNCED_EMAIL = 'MANAGE_BOUNCED_EMAIL',
  MANAGE_UNSUBSCRIBED_EMAIL = 'MANAGE_UNSUBSCRIBED_EMAIL',
  CONTACT_EDIT = 'CONTACT_EDIT',
  CONTACT_VIEW = 'CONTACT_VIEW',
  CONTACT_FIELD_EDIT = 'CONTACT_FIELD_EDIT',
  CONTACT_FIELD_VIEW = 'CONTACT_FIELD_VIEW',
  CONTACT_GROUP_EDIT = 'CONTACT_GROUP_EDIT',
  CONTACT_GROUP_VIEW = 'CONTACT_GROUP_VIEW',
  DGTL_FILING_EDIT = 'DGTL_FILING_EDIT',
  DGTL_FILING_VIEW = 'DGTL_FILING_VIEW',
  DGTL_FILING_ACTIVITY_EDIT = 'DGTL_FILING_ACTIVITY_EDIT',
  DGTL_FILING_ACTIVITY_VIEW = 'DGTL_FILING_ACTIVITY_VIEW',
  TRX_EMAIL_EDIT = 'TRX_EMAIL_EDIT',
  TRX_EMAIL_VIEW = 'TRX_EMAIL_VIEW',
  TRX_EMAIL_ACTIVITY_EDIT = 'TRX_EMAIL_ACTIVITY_EDIT',
  TRX_EMAIL_ACTIVITY_VIEW = 'TRX_EMAIL_ACTIVITY_VIEW',
  EMAIL_CAMPAIGN_EDIT = 'EMAIL_CAMPAIGN_EDIT',
  EMAIL_CAMPAIGN_VIEW = 'EMAIL_CAMPAIGN_VIEW',
  EMAIL_CAMPAIGN_ACTIVITY_EDIT = 'EMAIL_CAMPAIGN_ACTIVITY_EDIT',
  EMAIL_CAMPAIGN_ACTIVITY_VIEW = 'EMAIL_CAMPAIGN_ACTIVITY_VIEW',
  SMS_EDIT = 'SMS_EDIT',
  SMS_VIEW = 'SMS_VIEW',
  SMS_ACTIVITY_EDIT = 'SMS_ACTIVITY_EDIT',
  SMS_ACTIVITY_VIEW = 'SMS_ACTIVITY_VIEW',
  WA_EDIT = 'WA_EDIT',
  WA_VIEW = 'WA_VIEW',
  WA_ACTIVITY_EDIT = 'WA_ACTIVITY_EDIT',
  WA_ACTIVITY_VIEW = 'WA_ACTIVITY_VIEW',
  SEARCH_RECORD = 'SEARCH_RECORD',
  AUDIT = 'AUDIT',
  DASHBOARD = 'DASHBOARD',
}
