export enum DomainType {
  DIGITAL_FILING = 'DIGITAL_FILING',
  TRANSACTIONAL_EMAIL = 'TRANSACTIONAL_EMAIL',
  MT_TRANSACTIONAL_EMAIL = 'MT_TRANSACTIONAL_EMAIL',
  EMAIL_CAMPAIGN = 'EMAIL_CAMPAIGN',
  SMS = 'SMS',
  USER = 'USER',
  USER_PROFILE = 'USER_PROFILE',
  MAIL_SERVER = 'MAIL_SERVER',
  ACCOUNT = 'ACCOUNT',
  AFFILIATE_CODE = 'AFFILIATE_CODE',
  BOUNCED_EMAIL = 'BOUNCED_EMAIL',
  UNSUBSCRIBED_EMAIL = 'UNSUBSCRIBED_EMAIL',
  WHATSAPP_TEMPLATE = 'WHATSAPP_TEMPLATE',
  WHATSAPP = 'WHATSAPP',
  MT_WHATSAPP = 'MT_WHATSAPP',
  WHATSAPP_MESSAGE = 'WHATSAPP_MESSAGE',
  JOB = 'JOB',
  PLAN = 'PLAN',
  IMAGE = 'IMAGE',
  IMAGE_FOLDER = 'IMAGE_FOLDER',
  CONTACT = 'CONTACT',
  CONTACT_GROUP = 'CONTACT_GROUP',
  CONTACT_FIELD = 'CONTACT_FIELD',
  BILLING_INFO = 'BILLING_INFO',
  INVOICE = 'INVOICE',
  STRIPE_EVENT = 'STRIPE_EVENT',
  PROMO_CODE = 'PROMO_CODE',
}
