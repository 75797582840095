<div box>
  <div title><div logo class="mb-10"></div></div>
  <form nz-form [formGroup]="form" (ngSubmit)="login()">
    <nz-form-item>
      <nz-form-control [nzErrorTip]="getErrorMessage(form.controls['email'])">
        <nz-input-group nzPrefixIcon="user" nzSize="large">
          <input type="text" nz-input formControlName="email" placeholder="Email" autocomplete="username"/>
        </nz-input-group>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item>
      <nz-form-control [nzErrorTip]="getErrorMessage(form.controls['password'])">
        <nz-input-group nzPrefixIcon="lock" nzSize="large">
          <input type="password" nz-input formControlName="password" placeholder="Password"
                 autocomplete="current-password"/>
        </nz-input-group>
      </nz-form-control>
    </nz-form-item>
    <button nzSize="large"
            nzType="primary"
            nz-button
            nzBlock
            [nzLoading]="isLoading">
      Log in
    </button>
    <ng-container *ngIf="isSaas">
      <nz-divider nzText="OR"></nz-divider>
      <div>
        <button
          (click)="doGoogleLogin()"
          nzSize="large"
          nzType="default"
          nz-button
          nzBlock
          type="button"
          [nzLoading]="isGoogleLoading"
        >
          <i nz-icon nzType="google"></i>
          Sign In with Google
        </button>
      </div>
    </ng-container>

    <div class="text-center pt-4">
      <a [routerLink]="['/', 'forget-password']">Forgot password</a>
      <a *ngIf="isSaas" class="ml-8" [routerLink]="['/', 'register', 'user']">Register Now</a>
    </div>
  </form>
</div>
