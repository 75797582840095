export enum DataType {
  TEXT = 'TEXT',
  NUMBER = 'NUMBER',
  DATE = 'DATE',
  FILENAME = 'FILENAME',
  EMAIL = 'EMAIL',

  SELECT = 'SELECT',
  MULTIPLE = 'MULTIPLE',
}
